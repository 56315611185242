.loading-img{
    width: 300px; height: 500px;
}

.login-screen{
    margin-left: 150px; margin-right: 150px; background-color: #fcfcfb
}

.table-header{
    display: flex; justify-content: space-between; gap: 10px; flex-direction: row;
}

.view-page{
    display: flex; justify-content: space-between; gap: 20px; flex-direction: row;
}

.card-style{
    margin-left: 200px; margin-right: 200px;
}

.img-container, .input-group{
    width: 50%;
}

.selection{
    width: 100%;
}

@media screen and (max-width: 1000px) {
    .card-style{
        margin-left: 0px; margin-right: 0px;
    }
    .inputs-pack{
        flex-direction: column;
    }
    .selection{
        width: 50%;
    }
}

@media screen and (max-width: 800px) {
    .login-image{
        display: none;
    }
    .login-form{
        padding-left: 25px;
        padding-right: 25px;
    }
    .navbar-tabs{
        display: none;
    }
    .nav-width{
        width: 33%
    }
    .inputs{
        flex-direction: column;
    }
}

@media screen and (min-width: 801px) {
    .login-form{
        padding-left: 25px;
        padding-right: 25px;
    }
    .navbar-toggle{
        display: none;
    }
}

@media screen and (max-width: 600px) {
    .login-screen{
        margin: 0px;
    }
    .table-header{
        flex-direction: column;
    }
    .view-page{
        flex-direction: column;
    }
    .img-container{
        width: 100%;
    }
    .input-group{
        width: 100%;
    }
}

@media screen and (max-width: 500px) {
    .card-tabs-h{
        display: none;
    }
    .cust-table{
        margin-top: 500px;
    }
}

@media screen and (min-width: 501px) {
    .card-tabs-v{
        display: none;
    }
}

.delete-account-form{
    width: 100%;
}

@media screen and (min-width: 900px) {
    .delete-account-form{
        width: 50%;
    }
}

@media screen and (min-width: 600px) {
    .fit-page{
        padding-left: 48px; padding-right: 48px;
    }
}