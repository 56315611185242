@keyframes fadeIn {
    0% {
      opacity: 0;
      transform: translateY(2rem);
    }
    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  .profile-menu {
    animation: fadeIn 0.2s ease-in-out;
  }

  @keyframes fadeOut {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(2rem);
    }
  }
  
  .profile-menu--close {
    animation: fadeOut 0.2s ease-in-out;
  }